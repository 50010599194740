<template>
  <v-dialog v-model="dialog" retain-focus max-width="900" @click:outside="cancel" @keydown.esc="cancel">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Painel de Ações</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <!-- secondary buttons -->
        </v-toolbar-items>
      </v-toolbar>

      <v-container fluid class="px-4 py-6">
        <p class="text--secondary mb-1 ml-1">Busque por um cliente pelo nome, acrônimo ou nome do banco de dados.</p>
        <v-autocomplete v-model="selected" label="Buscar cliente" :items.sync="foundAccounts" :search-input.sync="search" outlined append-icon="search" />
        <p class="text--secondary mb-1 ml-1">Selecione um comando para ser executado no cliente escolhido.</p>
        <v-select v-model="selectedCommand" label="Selecionar comando" outlined :items="computedCommands" />
        <v-divider />
        <div v-if="selectedCommand && params">
          <v-card-title class="px-0 pb-0 mb-4">Parâmetros:</v-card-title>
          <v-alert
            v-if="selectedCommand === 'Importar clientes do ERP'"
            class="mb-6"
            type="info"
            outlined
            dense
            @click.native="newWindowRedirect('https://mercafacil.getoutline.com/doc/importacao-de-clientes-do-erp-82tmfNNzzd')"
            >Checar documentação de Importação de Clientes do ERP e certificar-se de que o arquivo siga os padrões estabelecidos.
          </v-alert>
          <v-card v-for="(param, index) in params" :key="index" flat>
            <span>{{ param.name.toUpperCase() + ` (${param.description}):` }}</span>
            <p class="text--secondary mb-1">{{ `Tipo: ${param.type.toUpperCase()} / Default: ${param.default}` }}</p>
            <v-switch v-if="param.type === 'boolean'" v-model="paramsInput[param.name]" class="px-2" value />
            <form v-else-if="param.type === 'csv'" enctype="multipart/form-data" class="mx-0 my-6">
              <input ref="fileUpload" :accept="`.${param.type}`" type="file" @change="onFileChange" />
            </form>
            <v-text-field v-else v-model="paramsInput[param.name]" class="py-0" :type="param.type" outlined />
          </v-card>
        </div>
        <v-btn color="primary" :disabled="!selected || !selectedCommand || executing" block @click="execute"
          ><v-icon class="mr-2">mdi-console-line</v-icon> EXECUTAR</v-btn
        >
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { QUERY_QUICK_ACCESS } from '@/modules/home/graphql'
import { MUTATION_EXECUTE_DEPLOYMENT_COMMAND, MUTATION_IMPORT_ERP_CLIENTS } from '@/modules/deployment/graphql'
import { QUERY_GET_GOOGLE_USER } from '../graphql'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    commands: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    search: '',
    selected: '',
    foundAccounts: [],
    selectedCommand: '',
    selectedCommandObj: {},
    params: null,
    paramsInput: {},
    csv: null,
    executing: false,
    user_id: ''
  }),
  computed: {
    computedCommands() {
      return this.commands?.map(command => command?.name) || []
    }
  },
  apollo: {
    getLoggedUser: {
      query: QUERY_GET_GOOGLE_USER,
      fetchPolicy: 'network-only',
      variables() {
        return { session_id: localStorage.getItem('session_id') }
      },
      update({ getLoggedUser }) {
        if (!getLoggedUser?.['_id']) return (this.user_id = '')
        this.user_id = getLoggedUser?.['_id']
      },
      error(err) {
        console.log('user.not.authenticated.or.session.expired', err.message)
      }
    }
  },
  watch: {
    search(value) {
      if (value && value.trim() !== '') this.getAccounts(value.toLowerCase())
    },
    selectedCommand(value) {
      const command = this.commands?.filter(command => command?.name === value)[0]
      this.params = command?.params
      this.selectedCommandObj = command
    }
  },
  methods: {
    async execute() {
      if (this.selectedCommandObj?.command === 'script_erp_users') return this.importErpClients()

      this.executing = true
      this.validateParams()
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_EXECUTE_DEPLOYMENT_COMMAND,
          variables: {
            db_name: this.selected,
            command: this.selectedCommandObj?.command,
            params: this.paramsInput
          }
        })

        this.cancel()
        this.$snackbar({ message: 'Comando executado com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao executar comando', snackbarColor: '#F44336' })
      }
      this.executing = false
    },
    async getAccounts(value) {
      setTimeout(async () => {
        const { data } = await this.$apollo.query({
          query: QUERY_QUICK_ACCESS,
          variables: { param: value }
        })
        this.foundAccounts = data.quickAccess.map(item => item.db_name)
      }, 1500)
    },
    async onFileChange(e) {
      this.csv = e.target.files[0]
    },
    async importErpClients() {
      if (!this.csv) return this.$snackbar({ message: 'Certifique-se de preencher os parâmetros corretamente', snackbarColor: '#F44336' })
      this.executing = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_IMPORT_ERP_CLIENTS,
          variables: {
            db_name: this.selected,
            command: this.selectedCommandObj?.command,
            file: this.csv,
            user_id: this.user_id
          }
        })
        this.cancel()
        this.$snackbar({ message: 'Comando executado com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao executar comando', snackbarColor: '#F44336' })
      }
      this.executing = false
    },
    cancel() {
      this.search = ''
      this.selected = ''
      this.foundAccounts = []
      this.selectedCommand = ''
      this.selectedCommandObj = {}
      this.paramsInput = {}
      if (this.$refs.fileUpload) this.$refs.fileUpload.value = null
      this.$emit('cancel')
    },
    validateParams() {
      this.params.map(param => {
        if (typeof this.paramsInput[param?.name] === 'undefined' && param?.type !== 'boolean') {
          this.paramsInput[param?.name] = param.default
        }

        if (param?.type === 'string' && typeof this.paramsInput[param?.name] !== 'string') {
          this.paramsInput[param?.name] = String(this.paramsInput[param?.name])
        }

        if (param?.type === 'number' && typeof this.paramsInput[param?.name] !== 'number') {
          this.paramsInput[param?.name] = Number(this.paramsInput[param?.name])
        }

        if (param?.type === 'boolean') {
          this.paramsInput[param?.name] = !(this.paramsInput[param?.name] === 'false' || typeof this.paramsInput[param?.name] === 'undefined')
        }
      })
    },
    newWindowRedirect(link) {
      return window.open(link, '_blank')
    }
  }
}
</script>
